import React, { useState, useEffect } from 'react';
import { useDispatch } from "react-redux";
import { SubmitVerificationPhoto } from "../redux/actions";
import { Success_Message, Error_Message, Toast_Message } from "../shared";
import { useHistory } from "react-router-dom";

export const AddVerificationPhoto = () => {
    let matricNo     = localStorage.getItem('matricNo');
    let emailAddress = localStorage.getItem('emailAddress');

    let history = useHistory();
    let res = {};
    const [showLoaderDiv, setShowLoaderDiv] = useState("none");
    const [loaderVisible, setLoaderVisible] = useState(false);

    const dispatch = useDispatch();

    useEffect(async () => {
        'use strict';
        var video = document.querySelector('video')
        , canvas;

    //     // take picture
        function takeSnapshot() {
            var img = document.createElement('img');
            var context;
            var width = video.offsetWidth
                , height = video.offsetHeight;

            canvas = canvas || document.createElement('canvas');
            canvas.width = width;
            canvas.height = height;

            context = canvas.getContext('2d');
            context.drawImage(video, 0, 0, width, height);

            img.src = canvas.toDataURL('image/png');

            window.img_upload_to_server = canvas.toDataURL('image/png');

            // document.body.appendChild(img);
            document.getElementById('upload_photo').src = img.src;

            // stop video
            window.windowStream.getVideoTracks()[0].stop();
            document.getElementById('video_off').style.display = 'none';
            // show image
            document.getElementById('upload_photo').style.display = 'initial';
            // hide the button to take photo taking photo once
            document.getElementById('take_sign_picture').style.display = 'none';
            document.getElementById('retake_photo').style.display = 'initial';
            // show the button that does not continue after taking photo
            document.getElementById('continue').style.display = 'initial';
        }

        // use MediaDevices API
        // docs: https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/getUserMedia
        if (navigator.mediaDevices) {
            // access the web cam
            navigator.mediaDevices.getUserMedia({video: true})
            // permission granted:
            .then(function(stream) {
                // video.src = window.URL.createObjectURL(stream);
                window.windowStream = stream;
                video.srcObject = stream;

                // Take picture on image click
                video.addEventListener('click', takeSnapshot);

                // Take picture on button click
                var take_picture = document.getElementById("take_sign_picture");
                take_picture.addEventListener('click', takeSnapshot);
            })
            // permission denied:
            .catch(function(error) {
                document.body.textContent = 'Could not access the camera. Error: ' + error.name;
            });
        }
    }, []);

    const uploadPhoto = async () => {
        // alert("Mr Mendes")
        console.log(window.img_upload_to_server)

        res = await dispatch(SubmitVerificationPhoto(window.img_upload_to_server, matricNo, emailAddress));

        if(res.status === "success"){ 
            window.location.href = '/verification-photo-successfully-added'; 
        }else{
            // setShowLoaderDiv("none"); setLoaderVisible(false);
            Toast_Message("warning", "An error occured. Please retake photo")
            setTimeout(function(){ window.location.reload(); }, 2000);    
        }
    }

    const goBack = async (e) => {
        history.goBack();
    }

    return (
        <div>
            <div className="row">
                
                <div className="col-md-1"></div>
                <div className="col-md-6">
                    <div className="container">
                        <div className="row" style={{marginTop: "50px", marginBottom: "25px"}}>
                            <div className="col-6">
                                
                                <span className="back-text" onClick={() => goBack()}>
                                    <i className="fas fa-long-arrow-alt-left back-arrow" alt="back-to-home"></i>
                                    Back
                                </span>
                            </div>
                            <div className="col-6">
                                <img src="../../images/lodl-logo.png" alt="lodl-logo" className="img-fluid" />
                            </div>
                        </div>
                        <hr></hr>
                        <p className="success-sub-text">
                            Please Make sure your face is centered, make sure you are not putting on a cap or glasses as this photo will be use for authentication when logging in.
                        </p>
                        <div className="row">
                            <div className="row text-center">
                                <div className="col-12">
                                    <video style={{maxWidth: '100%', height: 'auto'}} id="video_off" autoPlay />
                                    <img style={{display: 'none'}} src id="upload_photo" alt=" " />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 col-sm-6" style={{marginTop: "25px"}}>

                                    <button type="submit" id="take_sign_picture" className="form-submit-btn" >
                                            <span className="form-submit-btn-text">Take Photo</span>
                                    </button>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 col-sm-6" style={{marginTop: "25px"}}>
                                    <button type="submit" id="continue" style={{display: 'none'}}  className="form-submit-btn" onClick={() => uploadPhoto()}>
                                            <span className="form-submit-btn-text">Continue</span>
                                    </button>
                                </div>
                                <div className="col-md-6 col-sm-6" style={{marginTop: "25px"}}>
                                    <button type="submit" id="retake_photo" onClick={() => window.location.reload()} className="form-submit-btn" style={{display: 'none'}}>
                                            <span className="form-submit-btn-text">Retake Photo</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="col-md-1"></div> */}
                <div className="col-md-5">
                    <img src="../../images/balloon.jpg" className="img-fluid login-img" alt="side-img" />
                </div>
            </div>
        </div>
    )
}
