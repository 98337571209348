import React, { useState, useEffect } from 'react';
import { useDispatch } from "react-redux";
import { ImportStudentsList } from "../redux/actions";
import { Success_Message, Error_Message } from "../shared";
import { useHistory } from "react-router-dom";
import Loader from "react-loader-spinner";

export const ImportStudent = () => {
    // let matricNo     = localStorage.getItem('matricNo');
    // let emailAddress = localStorage.getItem('emailAddress');
    let res = {}
    const dispatch = useDispatch();
    let history = useHistory();
    const [showLoaderDiv, setShowLoaderDiv] = useState("none");
    const [loaderVisible, setLoaderVisible] = useState(false);
    const [imortedList, setImportedList] = useState("");

    useEffect(async () => {
        if(localStorage.getItem('isAuth')){
        }else {
            window.location.href = '/'; 
        }
    }, []);

    function handleChange(e) {
        setImportedList(e)
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('students_list', imortedList.target.files[0])
        // formData.append('students_list', imortedList)
        // document.getElementById("loader").style.display = "block";
        res = await dispatch(ImportStudentsList(formData))
        document.getElementById("loader").style.display = "none";
        if(res.status === "success"){ 
            await Success_Message("Successfully uploaded") 
            setTimeout(function(){ 
                window.location.reload();
            }, 2000);
        }else{
            Error_Message(res.data)
        }
    }

    const goBack = async (e) => {
        history.goBack();
    }

    return (
        <div>
            <div className="row">

                {/* // ///////////////////////////////// LOADER /////////////////////////////////////// */}
                <div id="loader" style={{position: "fixed", height:"100%", width:"100%", display: showLoaderDiv, zIndex: "1500"}}>
                    <div style={{position: "fixed", top:"50%", left:"46%",backgroundColor: "#ffffffcf",padding:"15px", borderRadius:"20px" }}>
                        <Loader
                            type="Bars"
                            color="#183287"
                            secondaryColor="#ff9700"
                            height={100}
                            width={100}
                            visible={loaderVisible}
                            // timeout={3000} //3 secs
                        />
                        <h6 style={{color: "#183287"}}>Loading...</h6>
                    </div>
                </div>
                {/* // ///////////////////////////////// LOADER /////////////////////////////////////// */}
                
                <div className="col-md-1"></div>
                <div className="col-md-6">
                    <div className="container">
                        <div className="row" style={{marginTop: "50px", marginBottom: "150px"}}>
                            <div className="col-6">
                                
                                <span className="back-text" onClick={() => goBack()}><img src="../../images/back.svg" alt="back-to-home" className="img-fluid back-icon" />  Back</span>
                            </div>
                            <div className="col-6">
                                <img src="../../images/lodl-logo.png" alt="lodl-logo" className="img-fluid" />
                            </div>
                        </div>
                        <div className="row">    
                            <div className="col-md-12">
                                <h1 className="back-office-title">Import Students</h1>
                                <p className="back-office-sub-title">Select the csv list of students in the proper format.</p>
                                <br></br>
                                <form onSubmit={handleSubmit}>
                                    <div className="mb-3">
                                        <label htmlFor="matricNo" className="form-label form-text">Matric No</label>
                                        <input 
                                            type="file" 
                                            name="matricNo"
                                            onChange={handleChange} 
                                            className={'form-control form-field'} 
                                            required
                                        />
                                    </div>
                                    <br></br>
                                    <button type="submit" className="form-submit-btn">
                                        <span className="form-submit-btn-text"> Submit </span>
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="col-md-1"></div> */}
                <div className="col-md-5">
                    <img src="../../images/balloon.jpg" className="img-fluid login-img" alt="side-img" />
                </div>
            </div>
        </div>
    )
}
