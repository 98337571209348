import React, { useState } from 'react';
import { useDispatch } from "react-redux";
import { validateMatricNo } from "../redux/actions";
import { useHistory } from "react-router-dom";
import { Success_Message, Error_Message, Warning_Message, Toast_Message } from "../shared";

export const ValidateMatricNo = () => {
    let history = useHistory();
    let res = {};

    const [form_data, setForm] = useState({
        matricNo: '',
        email: '',
    });

    const [submitted, setSubmitted] = useState(false);
    const dispatch = useDispatch();

    function handleChange(e) {
        const { name, value } = e.target;
        setForm(form_data => ({ ...form_data, [name]: value }));
    }


    async function handleSubmit(e) {
        e.preventDefault();
        
        setSubmitted(true);
        if (form_data.matricNo && form_data.email) {
            res = await dispatch(validateMatricNo(form_data.matricNo));

            if(res.status === "success"){ 
                localStorage.setItem("matricNo", form_data.matricNo);
                localStorage.setItem("emailAddress", form_data.email);
                // alertMessage(res);
                history.push('/matric-no-validated')
            }else if(res.status === "already-validated"){ 
                Toast_Message("warning", res.data)
            }else{
                alertMessage(res);
            }
        }
    }

    const alertMessage = async (res) => {
        if(res.status === "success"){ 
            Success_Message(res.data) 
        }else{
            Error_Message(res.data)
        }
    }

    return (
        <div>
            <div className="row">
                
                <div className="col-md-1"></div>
                <div className="col-md-6">
                    <div className="container">
                        <div className="row" style={{marginTop: "50px", marginBottom: "150px"}}>
                            <div className="col-12">
                                <img src="../../images/lodl-logo.png" alt="lodl-logo" className="img-fluid" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <h1 className="back-office-title">Validate Matric No</h1>
                                <p className="back-office-sub-title">Welcome to LAUTECH ODL Student Verification Portal</p>
                                <br></br>
                                <form onSubmit={handleSubmit}>
                                    <div className="mb-3">
                                        <label htmlFor="matricNo" className="form-label form-text">Matric No</label>
                                        <input 
                                            type="text" 
                                            name="matricNo" 
                                            id="matricNo" 
                                            value={form_data.matricNo} 
                                            onChange={handleChange} 
                                            className={'form-control form-field' + (submitted && !form_data.matricNo ? ' is-invalid' : '')} 
                                        />
                                        {submitted && !form_data.matricNo &&
                                            <div className="invalid-feedback">Matric No is required</div>
                                        }
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="email" className="form-label form-text">Email Address</label><br></br>
                                        <input 
                                            type="email" 
                                            name="email" 
                                            id="email" 
                                            value={form_data.email} 
                                            onChange={handleChange} 
                                            className={'form-control form-field' + (submitted && !form_data.email ? ' is-invalid' : '')} 
                                        />
                                        <small className="form-text-2">Please use your personal email address you can access</small>
                                        {submitted && !form_data.email &&
                                            <div className="invalid-feedback">Email is required</div>
                                        }
                                    </div>


                                    <br></br>
                                    <button type="submit" className="form-submit-btn">
                                        <span className="form-submit-btn-text"> Validate </span>
                                    </button>
                                </form>

                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="col-md-1"></div> */}
                <div className="col-md-5">
                    <img src="../../images/balloon.jpg" className="img-fluid login-img" alt="side-img" />
                </div>
            </div>
        </div>
    )
}
