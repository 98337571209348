import React, { useState, useEffect } from 'react';
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

export const LoginSuccessful = () => {
    let history = useHistory();
    let res = {};

    useEffect(async () => {
        if(localStorage.getItem('isAuth')){
        }else {
            window.location.href = '/'; 
        }
    }, []);

    const verifyStudent = async (e) => {
        history.push('/enter-matric-no')
    }

    const uploadNewStudents = async (e) => {
        history.push('/import-students')
    }

    const goBack = async (e) => {
        history.goBack();
    }

    return (
        <div>
            <div className="row">
                
                <div className="col-md-1"></div>
                <div className="col-md-6">
                    <div className="container">
                        <div className="row" style={{marginTop: "50px", marginBottom: "25px"}}>
                            <div className="col-6">
                                
                                <span className="back-text" onClick={() => goBack()}>
                                    <i className="fas fa-long-arrow-alt-left back-arrow" alt="back-to-home"></i> 
                                    Back
                                </span>
                            </div>
                            <div className="col-6">
                                <img src="../../images/lodl-logo.png" alt="lodl-logo" className="img-fluid" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <img src="../../images/successful.png" alt="successful" className="img-fluid" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <h1 className="success-text">Login Successful </h1>
                                <p className="success-sub-text">
                                    You have sucessfully log in. click the button below to verify student.
                                </p>
                                <br></br>
                                <div className="row">
                                    <div className="col-md-6 margin-bottom-15">
                                        <button type="submit" className="form-submit-btn" onClick={() => verifyStudent()}>
                                            <span className="form-submit-btn-text">Verify Student</span>
                                        </button>
                                    </div>
                                    <div className="col-md-6 margin-bottom-15">
                                        <button type="submit" className="form-submit-btn" onClick={() => uploadNewStudents()}>
                                            <span className="form-submit-btn-text">Upload New Students</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="col-md-1"></div> */}
                <div className="col-md-5">
                    <img src="../../images/balloon.jpg" className="img-fluid login-img" alt="side-img" />
                </div>
            </div>
        </div>
    )
}
