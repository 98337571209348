import React, { useState, useEffect } from 'react';
import { useDispatch } from "react-redux";
import { validateMatricNo2 } from "../redux/actions";
import { Success_Message, Error_Message, Warning_Message } from "../shared";
import { useHistory } from "react-router-dom";
import Loader from "react-loader-spinner";
import * as faceApi from "face-api.js";

export const VerifyStudent = () => {
    let history = useHistory();
    let res = {};
    const dispatch = useDispatch();
    const [showLoaderDiv, setShowLoaderDiv] = useState("none");
    const [loaderVisible, setLoaderVisible] = useState(false);
    const [matricNo, setMatricNo] = useState(localStorage.getItem("matricNo"));
    const [studentData, setStudentData] = useState([]);
    const [studentInfoDiv, setStudentInfoDiv] = useState("none");

    useEffect(async () => {
        if(localStorage.getItem('isAuth')){
        }else {
            window.location.href = '/'; 
        }
    }, []);

    useEffect(async () => {
        document.getElementById("loader").style.display = "block";
        loadResources();
        validateStudent();
        await verify();
        document.getElementById("loader").style.display = "none";
    }, []);

    const validateStudent = async (e) => {
        res = await dispatch(validateMatricNo2(localStorage.getItem("matricNo")));

        if(res.status === "success"){ 
            localStorage.setItem("name", res.data.name);
            localStorage.setItem("level", res.data.level);
            localStorage.setItem("email", res.data.email);
            setStudentData(res.data)
            localStorage.setItem("ErrorMsg", "");
        }else{
            // alert("validation error")
            // await Error_Message(res.data)
            if(res.data.matricNo) {
                localStorage.setItem("ErrorMsg", "An error occured");
            }else {
                localStorage.setItem("ErrorMsg", res.data);
            }
            window.location.href = '/enter-matric-no'; 
        }
    }

    const verify = async (e) => {
        'use strict';
        var video = document.querySelector('video')
        , canvas;

        // function to take the snapshot 
        const takeSnapshot = async () => {
            document.getElementById("loader").style.display = "block";
            
            var img = document.querySelector('img') || document.createElement('img');
            var context;
            var width = video.offsetWidth
                , height = video.offsetHeight;

            canvas = canvas || document.createElement('canvas');
            canvas.width = width;
            canvas.height = height;

            context = canvas.getContext('2d');
            context.drawImage(video, 0, 0, width, height);

            document.getElementById('img_div').src = canvas.toDataURL('image/png');
            // document.body.appendChild(img);
            document.getElementById('myImg1').src = canvas.toDataURL('image/png');

            // stop video and hide video after taking snapshot
            window.windowStream.getVideoTracks()[0].stop();
            document.getElementById('video_off').style.display = 'none';
            // show image
            document.getElementById('myImg1').style.display = 'block';
            // document.getElementById('img_div').style.display = 'block';

            // verify student
            await start();
        }

        // use MediaDevices API
        // docs: https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/getUserMedia
        if (navigator.mediaDevices) {
        // access the web cam
            try {
                const stream = await navigator.mediaDevices.getUserMedia({
                    video: true
                })  
                window.windowStream = stream;
                document.querySelector('video').srcObject = stream
            } catch(e) {
                console.error(e)
            }

            var take_picture = document.getElementById("take_picture");
            take_picture.addEventListener('click', takeSnapshot);
        }
    }

    const goBack = async (e) => {
        window.location.href = '/enter-matric-no';
    }
    // ///////////////////////////////////////////////////////////////////////////////////////////////
    const loadResources = async (e) => {
        await faceApi.nets.faceRecognitionNet.load('/models');
        await faceApi.nets.faceLandmark68Net.load('/models');
        await faceApi.nets.ssdMobilenetv1.load('/models');
        
        // await faceApi.nets.tinyFaceDetector.load('/models');
        // faceapi.nets.faceExpressionNet.loadFromUri('/models')
    }

    async function start() {
        // get image by id
        const imageUpload = document.getElementById('myImg1');
        // this container will overlay on the image and carry the canvas that detect face
        const container = document.createElement('div')
        document.getElementById('img_div').append(container)
        // get face label discriptions from the function loadLabeledImages
        const labeledFaceDescriptors = await loadLabeledImages()
        // pass the label discriptors into the facematcher as well as the percentage we want to approve as the person now we use 60%
        const faceMatcher = new faceApi.FaceMatcher(labeledFaceDescriptors, 0.6)
        let image
        let canvas
        
        if (image) image.remove()
        if (canvas) canvas.remove()
        try {
            // image = await faceApi.bufferToImage(imageUpload)
            container.append(imageUpload)
            // create canvas from the image added
            canvas = faceApi.createCanvasFromMedia(imageUpload)
            container.append(canvas)
        }
        catch(err) {
            // alert("please try again")
            await Warning_Message("Please try again")
            window.location.reload();
        }
        
        // the display size will be the size of the image
        const displaySize = { width: imageUpload.width, height: imageUpload.height }
        // the size of the convas should be the size of the image
        // faceApi.matchDimensions(canvas, displaySize)
        // takes the image input and detect all the faces on it
        const detections = await faceApi.detectAllFaces(imageUpload).withFaceLandmarks().withFaceDescriptors()
       
        // resize detection to take the size of the image
        const resizedDetections = faceApi.resizeResults(detections, displaySize)
        // find best match from the images loaded i.e highest %
        const results = resizedDetections.map(d => faceMatcher.findBestMatch(d.descriptor))
        // Check if face is detected
        if(detections.length == 0){
            // alert("No Face Detected");
            Error_Message("No Face Detected");
            // location.reload();
            setTimeout(function(){ window.location.reload(); }, 2000);
        }else{
            // foreach face detected draw a box around each face
            results.forEach((result, i) => {
                const box = resizedDetections[i].detection.box
                // label of the face
                // const drawBox = new faceApi.draw.DrawBox(box, { label: result.toString() })
                // drawBox.draw(canvas)

                var face_name = result.toString();
                var face_name1 = face_name.slice(0, -6) 
                var face_name2 = face_name.slice(0, -7) 
                // alert(face_name);
                if(face_name1 == 'unknown' || face_name2 == 'unknown'){
                    // alert('Invalid User, Please make sure face is centered');
                    Error_Message("Invalid User, Please make sure face is centered");
                    
                    setTimeout(function(){ window.location.reload(); }, 2500);
                }else{
                    // alert('Welcome!');
                    setStudentInfoDiv("block")
                    document.getElementById("loader").style.display         = "none";
                    document.getElementById("take_picture").style.display   = "none";
                    document.getElementById("verify_another").style.display = "block";
                }
            })
        }
    }
    
    // function to load images
    function loadLabeledImages() {
      // image labels in array
      var labels = matricNo;
      // var labels = "proctorstream"
      var labels = labels.split(" ");
      // const labels = ['Black Widow', 'Captain America', 'Captain Marvel', 'Hawkeye', 'Ikeade', 'Jim Rhodes', 'Mr Mendes', 'Thor', 'Tony Stark']
    
      return Promise.all(
        // loop through all labels asynchronously
        labels.map(async label => {
          const descriptions = []
          // number of images in each folder for now it is 2 if more that 2 increase loop range
          for (let i = 1; i <= 1; i++) {
            // load images from folder
            // const img = await faceApi.fetchImage(`https://raw.githubusercontent.com/WebDevSimplified/Face-Recognition-JavaScript/master/labeled_images/${label}/${i}.jpg`)
            // const img = await faceApi.fetchImage(`images/uploads/profile_picture/${label}/${i}.jpg`)
            // /////////////////////////////LOCAL ////////////////////
            // const img = await faceApi.fetchImage(`images/uploads/verification/${label}/${i}.jpg`)
            // //////////////////////////////ONLINE //////////////////////////
            const img = await faceApi.fetchImage(`backend/images/uploads/verification/${label}/${i}.jpg`)
    
            // detect each face from the image in the folder detectSingleFace because the images should have a single face to match with
            const detections = await faceApi.detectSingleFace(img).withFaceLandmarks().withFaceDescriptor()
          
            // each image discription gotten from our image is pushed into an empty array call discriptions
            descriptions.push(detections.descriptor)
          }
          // at the end of the loop pass label and discriptions into api
          return new faceApi.LabeledFaceDescriptors(label, descriptions)
        })
      )
    }
    // ///////////////////////////////////////////////////////////////////////////////////////////////
    return (
        <div>
            <div className="row">

                {/* // ///////////////////////////////// LOADER /////////////////////////////////////// */}
                <div id="loader" style={{position: "fixed", height:"100%", width:"100%", display: showLoaderDiv, zIndex: "1500"}}>
                    <div style={{position: "fixed", top:"50%", left:"46%",backgroundColor: "#ffffffcf",padding:"15px", borderRadius:"20px" }}>
                        <Loader
                            type="Bars"
                            color="#183287"
                            secondaryColor="#ff9700"
                            height={100}
                            width={100}
                            visible={loaderVisible}
                            // timeout={3000} //3 secs
                        />
                        <h6 style={{color: "#183287"}}>Loading...</h6>
                    </div>
                </div>
                {/* // ///////////////////////////////// LOADER /////////////////////////////////////// */}
                
                <div className="col-md-1"></div>
                <div className="col-md-6">
                    <div className="container">
                        <div className="row" style={{marginTop: "50px"}}>
                            <div className="col-6">
                                <span className="back-text" onClick={() => goBack()}>
                                    <i className="fas fa-long-arrow-alt-left back-arrow" alt="back-to-home"></i>
                                    Back
                                </span>
                            </div>
                            <div className="col-6">
                                <img src="../../images/lodl-logo.png" alt="lodl-logo" className="img-fluid" />
                            </div>
                        </div>
                        <div className="row">
                            
                            <h6>Facial Verification</h6>
                            <hr></hr>
                            <p>Please Make sure your face is centered, make sure you are not putting on a cap or glasses as this photo will be use for authentication when logging in.</p>
                            <hr></hr>
                            <div className="row">
                                    <div className="col-md-12" style={{display: studentInfoDiv}}> 
                                        <div className="card">
                                            <div className="card-body">
                                                <table className="table table-hover table-striped table-bordered">
                                                    <tbody>
                                                        <tr><td>Name</td><td> {studentData.name} </td></tr>
                                                        <tr><td>Level</td><td>{studentData.level} </td></tr>
                                                        <tr><td>Email</td><td>{studentData.email}</td></tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-12">  
                                        <div style={{backgroundColor: '#fff', padding: 20, width: 500, marginLeft: 'auto', marginRight: 'auto'}}>
                                            <img style={{ display: 'none', width: '100%', height: '100%'}} src id="myImg1" alt=" " />
                                            <p id="img_div" style={{display: 'none', width: '100%', height: 'auto'}} />
                                            <video style={{maxWidth: '100%', height: 'auto'}} id="video_off" autoPlay />
                                        </div>
                                        <div className="row border-secondary">
                                            <div className="col-12" style={{paddingRight: '15%', paddingLeft: '15%'}}>
                                                <div style={{textAlign: "center"}}>
                                                    <button type="submit" className="form-submit-btn" id="take_picture">
                                                        <span className="form-submit-btn-text">Verify Student</span>
                                                    </button>

                                                    <button style={{display: "none"}} type="submit" className="form-submit-btn" id="verify_another" onClick={() => goBack()}>
                                                        <span className="form-submit-btn-text">Verify Another Student</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                <br></br>
                            </div><br></br>
                        </div>
                    </div>
                </div>
                {/* <div className="col-md-1"></div> */}
                <div className="col-md-5">
                    <img src="../../images/balloon.jpg" className="img-fluid login-img" alt="side-img" />
                </div>
            </div>
        </div>
    )
}