import { useState } from 'react';
import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router } from "react-router-dom";
import { AnimatedSwitch } from 'react-router-transition';
import { PublicRoute } from "./components/PublicRoute";
import { PrivateRoute } from "./components/PrivateRoute";

import { ValidateMatricNo, MatricNoValidated, AddVerificationPhoto, VerificationAddedSuccessfully, Manager, LoginSuccessful, EnterMatricNo, VerifyStudent, ImportStudent } from "./pages";
import Loader from "react-loader-spinner";

function App() {
  const [showLoaderDiv, setShowLoaderDiv] = useState("none");

  return (
    <div>
      {/* // ///////////////////////////////// LOADER /////////////////////////////////////// */}
      <div id="loader" style={{position: "fixed", height:"100%", width:"100%", display: showLoaderDiv, zIndex: "1500"}}>
          <div style={{position: "fixed", top:"40%", left:"46%",backgroundColor: "#ffffffcf",padding:"15px", borderRadius:"20px", textAlign: "center" }}>
              <Loader
                  type="Bars"
                  color="#183287"
                  secondaryColor="#ff9700"
                  height={50}
                  width={50}
                  // visible={loaderVisible}
                  // timeout={3000} //3 secs
              />
              <h6 style={{color: "#183287"}}>Loading...</h6>
          </div>
      </div>
      {/* // ///////////////////////////////// LOADER /////////////////////////////////////// */}
      <Router>
        <AnimatedSwitch
          atEnter={{ opacity: 0 }}
          atLeave={{ opacity: 0 }}
          atActive={{ opacity: 1 }}
          className="switch-wrapper"
        >
          <PublicRoute path="/" exact component={ValidateMatricNo} />
          <PublicRoute path="/matric-no-validated" exact component={MatricNoValidated} />
          <PublicRoute path="/add-verification-photo" exact component={AddVerificationPhoto} />
          <PublicRoute path="/verification-photo-successfully-added" exact component={VerificationAddedSuccessfully} />

          <PublicRoute path="/manager" exact component={Manager} />
          <PrivateRoute exact path="/login-successful">
            <LoginSuccessful />
          </PrivateRoute>
          <PrivateRoute exact path="/enter-matric-no">
            <EnterMatricNo />
          </PrivateRoute>
          <PrivateRoute exact path="/verify-student">
            <VerifyStudent />
          </PrivateRoute>
          <PrivateRoute exact path="/import-students">
            <ImportStudent />
          </PrivateRoute>

        </AnimatedSwitch>
      </Router>
    </div>
    
  );
}

export default App;
