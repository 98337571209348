import React, { useState } from 'react';
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

export const MatricNoValidated = () => {
    let history = useHistory();
    let res = {};

    const facialVerification = async (e) => {
        history.push('/add-verification-photo')
    }

    const goBack = async (e) => {
        history.goBack();
    }

    return (
        <div>
            <div className="row">
                
                <div className="col-md-1"></div>
                <div className="col-md-6">
                    <div className="container">
                        <div className="row" style={{marginTop: "50px", marginBottom: "25px"}}>
                            <div className="col-6">
                                
                                <span className="back-text" onClick={() => goBack()}>
                                    <i className="fas fa-long-arrow-alt-left back-arrow" alt="back-to-home"></i> 
                                    Back
                                </span>
                            </div>
                            <div className="col-6">
                                <img src="../../images/lodl-logo.png" alt="lodl-logo" className="img-fluid" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <img src="../../images/successful.png" alt="successful" className="img-fluid" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <h1 className="success-text">Validation Successfully </h1>
                                <p className="success-sub-text">
                                    You have sucessfully validated your matric number. click the button below to enter your verification image.
                                </p>
                                <br></br>
                                <div className="row">
                                    <div className="col-md-12">
                                        <button type="submit" className="form-submit-btn" onClick={() => facialVerification()}>
                                            <span className="form-submit-btn-text"> Proceed to Add Your Photo</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="col-md-1"></div> */}
                <div className="col-md-5">
                    <img src="../../images/balloon.jpg" className="img-fluid login-img" alt="side-img" />
                </div>
            </div>
        </div>
    )
}
