import React, { useState } from 'react';
import { useDispatch } from "react-redux";
import { ManagerLogin } from "../redux/actions";
import { useHistory } from "react-router-dom";
import { Success_Message, Error_Message } from "../shared";

export const Manager = () => {
    let history = useHistory();
    let res = {};

    const [form_data, setForm] = useState({
        username: '',
        password: '',
    });

    const [submitted, setSubmitted] = useState(false);
    const dispatch = useDispatch();

    function handleChange(e) {
        const { name, value } = e.target;
        setForm(form_data => ({ ...form_data, [name]: value }));
    }


    async function handleSubmit(e) {
        e.preventDefault();
        
        setSubmitted(true);
        if (form_data.username && form_data.password) {
            res = await dispatch(ManagerLogin(form_data));

            if(res.status === "success"){ 
                localStorage.setItem("isAuth", true);
                history.push('/login-successful')
            }else{
                alertMessage(res);
            }
        }
    }

    const alertMessage = async (res) => {
        if(res.status === "success"){ 
            Success_Message(res.data) 
        }else{
            Error_Message(res.data)
        }
    }

    return (
        <div>
            <div className="row">
                
                <div className="col-md-1"></div>
                <div className="col-md-6">
                    <div className="container">
                        <div className="row" style={{marginTop: "50px", marginBottom: "150px"}}>
                            <div className="col-12">
                                <img src="../../images/lodl-logo.png" alt="lodl-logo" className="img-fluid" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <h1 className="back-office-title">Invigilator Login</h1>
                                <p className="back-office-sub-title">Welcome to LAUTECH ODL Student Verification Portal</p>
                                <br></br>
                                <form onSubmit={handleSubmit}>
                                    <div className="mb-3">
                                        <label htmlFor="username" className="form-label form-text">Username</label>
                                        <input 
                                            type="text" 
                                            name="username" 
                                            id="username" 
                                            value={form_data.username} 
                                            onChange={handleChange} 
                                            className={'form-control form-field' + (submitted && !form_data.username ? ' is-invalid' : '')} 
                                        />
                                        {submitted && !form_data.username &&
                                            <div className="invalid-feedback">Matric No is required</div>
                                        }
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="password" className="form-label form-text">Password</label><br></br>
                                        <input 
                                            type="password" 
                                            name="password" 
                                            id="password" 
                                            value={form_data.password} 
                                            onChange={handleChange} 
                                            className={'form-control form-field' + (submitted && !form_data.password ? ' is-invalid' : '')} 
                                        />
                                        {submitted && !form_data.password &&
                                            <div className="invalid-feedback">Email is required</div>
                                        }
                                    </div>


                                    <br></br>
                                    <button type="submit" className="form-submit-btn">
                                        <span className="form-submit-btn-text"> Validate </span>
                                    </button>
                                </form>

                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="col-md-1"></div> */}
                <div className="col-md-5">
                    <img src="../../images/balloon.jpg" className="img-fluid login-img" alt="side-img" />
                </div>
            </div>
        </div>
    )
}
